import PostlyLight from "@/assets/svgs/PostlyLight.svg";

const SiteMetaData = {


    dashboard: "https://app.postly.ai",
    register: "https://app.postly.ai/register",

    //Publisher tools    
    bulkPostCSVURL: "https://docs.google.com/spreadsheets/d/1RB_-VdT7BOzE80KDQIKY8YRuz_JxwrjeE9yQhjkGIjs/edit?usp=sharing",
    bulkPostDemoVideoURL: "https://youtube.com/watch?v=IdiL9KLZKMU",
    bulkWorkspaceCSVURL: "https://docs.google.com/spreadsheets/d/11PHQETJFmpInGHkdcxoIZ0aTMgFKEf6TYZik-0NeTfE/edit?usp=sharing",

    //Resources     
    blogDomainURL: "https://blog.postly.ai",
    buyUsACoffee: "https://buymeacoffee.com/postly",
    supportEmail: "mailto:support@postly.ai",
    teamEmail: "mailto:team@postly.ai",
    twitterPage: "https://x.com/postlysocial",
    facebookPage: "https://facebook.com/postlysocial",
    instagramPage: "https://instagram.com/postlysocial",
    linkedinPage: "https://linkedin.com/company/postlysocial/?viewAsMember=true",
    youtubeChannel: "https://youtube.com/@postlysocial",
    facebookCommunity: "https://facebook.com/groups/postly.community",
    demoVideoChannel: "https://youtube.com/playlist?list=PL8n6MN4aSb28-owT1geBv7Guk7-zGyaoL",
    productUpdate: "https://trello.com/b/wclP8IjJ/product-update",
    facebookConnectionFullDemo: "https://youtu.be/DRnm4Z8ouuQ",
    facebookMultipleAccountConnectionDemo: "https://youtube.com/watch?v=nguaGc9VgsU",
    instagramConnectionFullDemo: "https://youtu.be/qlKuP_10QsM",
    linkInstagramToFacebookPage: "https://youtube.com/watch?v=wpLVJCY7-fc&t=2s",
    instagramMultipleAccountConnectionDemo: "https://youtube.com/watch?v=9UCu6uDJmCI",
    twitterConnectionFullDemo: "https://youtube.com/watch?v=wcYh1PaEN14",
    twitterDeveloperAccountURL: "https://developer.x.com/en/portal/petition/essential/basic-info",
    twitterCallBackURL: "https://api.postly.ai/v1/workspaces/oauth/callback/twitter.com",
    bookDemoURL: "https://calendly.com/postly/ai?month=2024-01",
    youTubeShortsGuideURL: "https://support.google.com/youtube/answer/6375112",
    feedbackForm: "https://airtable.com/appLoSZp7g2S72tz2/shrIaIlN8saXJN6bC",
    whySocialAccountsDisconnectBlogURL: "https://blog.postly.ai/why-social-accounts-disconnect/",
    organizationsURL: "https://www.youtube.com/watch?v=Kb1PK9igIFw",
    privacyPolicyURL: "https://postly.ai/docs/privacy-policy",
    termsOfServiceURL: "https://postly.ai/docs/terms-of-service",
    //pending resources
    google_play: "https://postly.ai",
    app_store: "https://postly.ai",



    //1 Home page
    title: "AI-Powered Content Creation, Publishing and Monetization.",
    description: "Write once, publish everywhere. Use AI to generate content, distribute it to top platforms, and monetize your services—all from one powerful platform.",
    canonicalUrl: "https://postly.ai",
    image: { PostlyLight },  // Add your image path here
    keywords: ["AI social media management tool", "Social media automation", "Post scheduler", "AI content planner", "Marketing automation", "Content publisher for businesses", "ai social media automation", "best social media management tools", "scheduling software", "social media marketing and management", "social media scheduler", "social media posting schedule", "social media scheduling tools", "social media scheduling app", "best social media scheduling tools", "social media scheduling software", "automated social media posting", "schedule posts across platforms", "post scheduling tool", "social media calendar", "social media planning calendar"],
    author: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai", // Ensure this matches the canonical URL
        },
    },

    twitter: {
        cardType: "summary_large_image",
        site: "@postly",
        image: { PostlyLight },  // Add your image path here
        image_alt: "Postly - AI-Powered Marketing and Media Hub",  // Add alt text for accessibility
    },

    og: {
        title: "Write once, publish everywhere. Use AI to generate content, distribute it to top platforms, and monetize your services—all from one powerful platform.",
        description: "All-in-One Marketing and Media Publishing Hub: Streamline Content Creation.",
        url: "https://postly.ai",
        image: "/path-to-your-image.jpg",  // Add your image path here
        type: "website",
    },

    language: "en",
    publishedDate: "2023-12-12T12:00:00Z",
    modifiedDate: "2023-12-12T14:30:00Z",
    category: "landing page",
    tags: ["AI social media management tool", "Social media automation", "Post scheduler", "AI content planner", "Marketing automation", "Content publisher for businesses", "ai social media automation", "best social media management tools", "scheduling software", "social media marketing and management", "social media scheduler", "social media posting schedule", "social media scheduling tools", "social media scheduling app", "best social media scheduling tools", "social media scheduling software", "automated social media posting", "schedule posts across platforms", "post scheduling tool", "social media calendar", "social media planning calendar"],

    robots: {
        index: true,
        follow: true,
        googleBot: {
            index: true,
            follow: true,
            noimageindex: false,  // Allow image indexing
            "max-video-preview": -1,
            "max-image-preview": "large",
            "max-snippet": -1,
        },
    },



    //2 Login
    loginTitle: "Postly Login",
    loginDescription: "Access your Postly account securely.",
    loginCanonicalUrl: "https://postly.ai/login",
    loginKeywords: ["Postly login", "Social media tool login", "Account access Postly"],
    loginAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/login",  // Ensure this matches the login canonical URL
        },
    },

    og: {
        title: "Postly Login",
        description: "Access your Postly account securely.",
        url: "https://postly.ai/login",
        type: "website",
    },

    loginPublishedDate: "2023-12-12T12:00:00Z",
    loginModifiedDate: "2023-12-12T14:30:00Z",
    loginCategory: "Product login",
    loginTags: ["Postly login", "Social media tool login"],


    //3 Pricing page 
    pricingTitle: "Postly Plans & Pricing",
    pricingDescription: "Affordable social media scheduler with AI tools.",
    pricingCanonicalUrl: "https://postly.ai/pricing",
    pricingKeywords: ["Affordable social media scheduler", "Free social media tools", "Cheap post scheduler"],
    pricingAuthor: "Collins Rollins",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/pricing",  // Ensure this matches the pricing canonical URL
        },
    },

    og: {
        title: "Postly Plans & Pricing",
        description: "Affordable social media scheduler with AI tools.",
        url: "https://postly.ai/pricing",
        type: "website",
    },

    pricingPublishedDate: "2023-12-12T12:00:00Z",
    pricingModifiedDate: "2023-12-12T14:30:00Z",
    pricingCategory: "Product Pricing",
    pricingTags: ["Affordable social media scheduler", "Free social media tools", "Cheap post scheduler"],


    // 4 Facebook platform page 
    facebookTitle: "Schedule Posts on Facebook",
    facebookDescription: "Auto-post to Facebook pages, reels, and stories.",
    facebookCanonicalUrl: "https://postly.ai/facebook",
    facebookKeywords: ["social media platforms supported", "social media scheduling platforms", "post automation platforms", "overview of social media platforms", "social media scheduling tools", "all-in-one social media management", "social media post automation app", "platforms for social media management", "manage multiple social media platforms", "platform integrations for social media", "automated facebook posts", "best times to post on facebook", "social media scheduling for facebook", "Facebook post scheduler", "Auto-post to Facebook", "Grow business on Facebook"],
    facebookAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/facebook",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Schedule Posts on Facebook",
        description: "Auto-post to Facebook pages, reels, and stories.",
        url: "https://postly.ai/facebook",
        type: "website",
    },

    facebookPublishedDate: "2023-12-12T12:00:00Z",
    facebookModifiedDate: "2023-12-12T14:30:00Z",
    facebookCategory: "Facebook publishing",
    facebookTags: ["social media platforms supported", "social media scheduling platforms", "post automation platforms", "overview of social media platforms", "social media scheduling tools", "all-in-one social media management", "social media post automation app", "platforms for social media management", "manage multiple social media platforms", "platform integrations for social media", "automated facebook posts", "best times to post on facebook", "social media scheduling for facebook", "Facebook post scheduler", "Auto-post to Facebook", "Grow business on Facebook"],


    // 4b. Threads platform page 
    threadsTitle: "Schedule Posts on Threads",
    threadsDescription: "Auto-post to Threads with our scheduler.",
    threadsCanonicalUrl: "https://postly.ai/threads",
    threadsKeywords: ["schedule posts on threads", "automated threads posts", "threads content management", "best times to post on threads", "threads scheduling tool", "Threads post scheduler", "Auto-post to Threads", "Grow on Threads"],
    threadsAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/threads",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Schedule Posts on Threads",
        description: "Auto-post to Threads with our scheduler.",
        url: "https://postly.ai/threads",
        type: "website",
    },

    threadsPublishedDate: "2023-12-12T12:00:00Z",
    threadsModifiedDate: "2023-12-12T14:30:00Z",
    threadsCategory: "Threads publishing",
    threadsTags: ["schedule posts on threads", "automated threads posts", "threads content management", "best times to post on threads", "threads scheduling tool", "Threads post scheduler", "Auto-post to Threads", "Grow on Threads"],


    // 5 Instagram platform page 
    instagramTitle: "Schedule Posts on Instagram",
    instagramDescription: "Auto-post reels and stories to Instagram.",
    instagramCanonicalUrl: "https://postly.ai/instagram",
    instagramKeywords: ["schedule posts on instagram", "instagram post scheduler", "instagram post automation", "automated instagram posts", "instagram content scheduling", "best times to post on instagram", "instagram scheduling tool", "Instagram post scheduler", "Auto-post to Instagram", "Grow on Instagram"],
    instagramAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/instagram",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Schedule Posts on Instagram",
        description: "Auto-post reels and stories to Instagram.",
        url: "https://postly.ai/instagram",
        type: "website",
    },

    instagramPublishedDate: "2023-12-12T12:00:00Z",
    instagramModifiedDate: "2023-12-12T14:30:00Z",
    instagramCategory: "Instagram publishing",
    instagramTags: ["schedule posts on instagram", "instagram post scheduler", "instagram post automation", "automated instagram posts", "instagram content scheduling", "best times to post on instagram", "instagram scheduling tool", "Instagram post scheduler", "Auto-post to Instagram", "Grow on Instagram"],


    // 6 Twitter platform page 
    twitterTitle: "Schedule Posts on Twitter",
    twitterDescription: "Auto-post tweets with our scheduler.",
    twitterCanonicalUrl: "https://postly.ai/twitter",
    twitterKeywords: ["schedule posts on twitter", "twitter post scheduler", "automated twitter posts", "best times to post on twitter", "twitter post automation", "Twitter post scheduler", "Auto-post to Twitter", "Grow on Twitter"],
    twitterAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/twitter",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Schedule Posts on Twitter",
        description: "Auto-post tweets with our scheduler.",
        url: "https://postly.ai/twitter",
        type: "website",
    },

    twitterPublishedDate: "2023-12-12T12:00:00Z",
    twitterModifiedDate: "2023-12-12T14:30:00Z",
    twitterCategory: "Twitter publishing",
    twitterTags: ["schedule posts on twitter", "twitter post scheduler", "automated twitter posts", "best times to post on twitter", "twitter post automation", "Twitter post scheduler", "Auto-post to Twitter", "Grow on Twitter"],


    // 7 LinkedIn platform page 
    linkedinTitle: "Schedule Posts on LinkedIn",
    linkedinDescription: "Auto-post content to LinkedIn.",
    linkedinCanonicalUrl: "https://postly.ai/linkedin",
    linkedinKeywords: ["schedule posts on LinkedIn", "LinkedIn post scheduler", "automated LinkedIn posts", "LinkedIn content management", "best times to post on LinkedIn", "LinkedIn post automation", "LinkedIn post scheduler", "Auto-post to LinkedIn", "Grow on LinkedIn"],
    linkedinAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/linkedin",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Schedule Posts on LinkedIn",
        description: "Auto-post content to LinkedIn.",
        url: "https://postly.ai/linkedin",
        type: "website",
    },

    linkedinPublishedDate: "2023-12-12T12:00:00Z",
    linkedinModifiedDate: "2023-12-12T14:30:00Z",
    linkedinCategory: "LinkedIn publishing",
    linkedinTags: ["schedule posts on LinkedIn", "LinkedIn post scheduler", "automated LinkedIn posts", "LinkedIn content management", "best times to post on LinkedIn", "LinkedIn post automation", "LinkedIn post scheduler", "Auto-post to LinkedIn", "Grow on LinkedIn"],


    // 8 YouTube platform page 
    youtubeTitle: "Schedule Posts on YouTube",
    youtubeDescription: "Auto-post videos with YouTube scheduler.",
    youtubeCanonicalUrl: "https://postly.ai/youtube",
    youtubeKeywords: ["schedule posts on YouTube", "YouTube post scheduler", "Automated YouTube posts", "YouTube content management", "Best times to post on YouTube", "YouTube scheduling tool", "YouTube post scheduler", "Auto-post to YouTube", "Grow on YouTube"],
    youtubeAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/youtube",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Schedule Posts on YouTube",
        description: "Auto-post videos with YouTube scheduler.",
        url: "https://postly.ai/youtube",
        type: "website",
    },

    youtubePublishedDate: "2023-12-12T12:00:00Z",
    youtubeModifiedDate: "2023-12-12T14:30:00Z",
    youtubeCategory: "YouTube publishing",
    youtubeTags: ["schedule posts on YouTube", "YouTube post scheduler", "Automated YouTube posts", "YouTube content management", "Best times to post on YouTube", "YouTube scheduling tool", "YouTube post scheduler", "Auto-post to YouTube", "Grow on YouTube"],


    // 9 Google Business Profile platform page 
    googleBusinessProfileTitle: "Schedule posts on Google Business Profile, GBP scheduler, GMB scheduler",
    googleBusinessProfileDescription: "Schedule posts on Google Business Profile. GBP scheduler, GMB scheduler",
    googleBusinessProfileCanonicalUrl: "https://postly.ai/google-business-profile",
    googleBusinessProfileKeywords: ["Google business profile post scheduler", "Automated posts for Google Business Profile", "Manage Google Business Profile posts", "Google Business Profile content scheduling", "Google Business Profile post automation", "schedule post on Google Business Profile", "auto-post to Google Business Profile", "grow your business on Google Business Profile, GBP scheduler, GMB scheduler", "auto-post to Google business profile, GBP scheduler, GMB scheduler"],
    googleBusinessProfileAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/google-business-profile",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Schedule posts on Google Business Profile, GBP scheduler, GMB scheduler",
        description: "Schedule posts on Google Business Profile. GBP scheduler, GMB scheduler",
        url: "https://postly.ai/google-business-profile",
        type: "website",
    },

    googleBusinessProfilePublishedDate: "2023-12-12T12:00:00Z",
    googleBusinessProfileModifiedDate: "2023-12-12T14:30:00Z",
    googleBusinessProfileCategory: "Google Business Profile publishing",
    googleBusinessProfileTags: ["Google business profile post scheduler", "Automated posts for Google Business Profile", "Manage Google Business Profile posts", "Google Business Profile content scheduling", "Google Business Profile post automation", "schedule post on Google Business Profile", "auto-post to Google Business Profile", "grow your business on Google Business Profile, GBP scheduler, GMB scheduler", "auto-post to Google business profile, GBP scheduler, GMB scheduler"],


    // 10 TikTok platform page 
    tiktokTitle: "Schedule posts on TikTok, Tiktok scheduler",
    tiktokDescription: "Schedule posts on TikTok. Tiktok scheduler",
    tiktokCanonicalUrl: "https://postly.ai/tiktok",
    tiktokKeywords: ["TikTok post scheduler", "Automated TikTok posts", "TikTok content management", "Best times to post on TikTok", "TikTok scheduling tool", "schedule post on TikTok", "auto-post to TikTok", "grow your business on TikTok", "auto-post to tiktok, Tiktok scheduler"],
    tiktokAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/tiktok",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Schedule posts on TikTok, Tiktok scheduler",
        description: "Schedule posts on TikTok. Tiktok scheduler",
        url: "https://postly.ai/tiktok",
        type: "website",
    },

    tiktokPublishedDate: "2023-12-12T12:00:00Z",
    tiktokModifiedDate: "2023-12-12T14:30:00Z",
    tiktokCategory: "TikTok publishing",
    tiktokTags: ["TikTok post scheduler", "Automated TikTok posts", "TikTok content management", "Best times to post on TikTok", "TikTok scheduling tool", "schedule post on TikTok", "auto-post to TikTok", "grow your business on TikTok", "auto-post to tiktok, Tiktok scheduler"],


    // 11 Pinterest platform page 
    pinterestTitle: "Schedule posts on Pinterest, Pinterest scheduler",
    pinterestDescription: "Schedule posts on Pinterest. Pinterest scheduler",
    pinterestCanonicalUrl: "https://postly.ai/pinterest",
    pinterestKeywords: ["Pinterest post scheduler", "Automated Pinterest posts", "Pinterest content management", "Pinterest scheduling tool", "schedule post on Pinterest", "auto-post to Pinterest", "grow your business on Pinterest", "auto-post to pinterest, Pinterest scheduler"],
    pinterestAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/pinterest",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Schedule posts on Pinterest, Pinterest scheduler",
        description: "Schedule posts on Pinterest. Pinterest scheduler",
        url: "https://postly.ai/pinterest",
        type: "website",
    },

    pinterestPublishedDate: "2023-12-12T12:00:00Z",
    pinterestModifiedDate: "2023-12-12T14:30:00Z",
    pinterestCategory: "Pinterest publishing",
    pinterestTags: ["Pinterest post scheduler", "Automated Pinterest posts", "Pinterest content management", "Pinterest scheduling tool", "schedule post on Pinterest", "auto-post to Pinterest", "grow your business on Pinterest", "auto-post to pinterest, Pinterest scheduler"],


    // 12 Telegram platform page 
    telegramTitle: "Schedule posts on Telegram, Telegram scheduler",
    telegramDescription: "Schedule posts on Telegram. Telegram scheduler",
    telegramCanonicalUrl: "https://postly.ai/telegram",
    telegramKeywords: ["Telegram post scheduler", "Automated Telegram posts", "Telegram content management", "Telegram scheduling tool", "schedule post on Telegram", "auto-post to Telegram", "grow your business on Telegram", "auto-post to telegram, Telegram scheduler"],
    telegramAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/telegram",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Schedule posts on Telegram, Telegram scheduler",
        description: "Schedule posts on Telegram. Telegram scheduler",
        url: "https://postly.ai/telegram",
        type: "website",
    },

    telegramPublishedDate: "2023-12-12T12:00:00Z",
    telegramModifiedDate: "2023-12-12T14:30:00Z",
    telegramCategory: "Telegram publishing",
    telegramTags: ["Telegram post scheduler", "Automated Telegram posts", "Telegram content management", "Telegram scheduling tool", "schedule post on Telegram", "auto-post to Telegram", "grow your business on Telegram", "auto-post to telegram, Telegram scheduler"],
    // 13 Reddit platform page 
    redditTitle: "Schedule posts on Reddit, Reddit scheduler",
    redditDescription: "Schedule posts on Reddit. Reddit scheduler",
    redditCanonicalUrl: "https://postly.ai/reddit",
    redditKeywords: ["Reddit post scheduler", "Automated Reddit posts", "Reddit content management", "Reddit scheduling tool", "schedule post on Reddit", "auto-post to Reddit", "grow your business on Reddit", "auto-post to reddit, Reddit scheduler"],
    redditAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/reddit",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Schedule posts on Reddit, Reddit scheduler",
        description: "Schedule posts on Reddit. Reddit scheduler",
        url: "https://postly.ai/reddit",
        type: "website",
    },

    redditPublishedDate: "2023-12-12T12:00:00Z",
    redditModifiedDate: "2023-12-12T14:30:00Z",
    redditCategory: "Reddit publishing",
    redditTags: ["Reddit post scheduler", "Automated Reddit posts", "Reddit content management", "Reddit scheduling tool", "schedule post on Reddit", "auto-post to Reddit", "grow your business on Reddit", "auto-post to reddit", "Reddit scheduler"],


    // 15 AI Chat page 
    aiChatTitle: "AI Chat",
    aiChatDescription: "Your AI-powered Chat companion!",
    aiChatCanonicalUrl: "https://postly.ai/free-tools/ai-chat",
    aiChatKeywords: ["AI content generator", "AI social media post generator", "Automatic social media posting", "best ai-writer", "best ai social media scheduler", "ai social media automation", "ai post scheduler", "post to all social media at once", "ai social media content planner", "AI chat"],
    aiChatAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/ai-chat",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "AI Chat",
        description: "Your AI-powered Chat companion!",
        url: "https://postly.ai/free-tools/ai-chat",
        type: "website",
    },

    aiChatPublishedDate: "2023-12-12T12:00:00Z",
    aiChatModifiedDate: "2023-12-12T14:30:00Z",
    aiChatCategory: "AI Chat",
    aiChatTags: ["AI content generator", "AI social media post generator", "Automatic social media posting", "best ai-writer", "best ai social media scheduler", "ai social media automation", "ai post scheduler", "post to all social media at once", "ai social media content planner", "AI chat"],


    // 16 Post Writer page 
    postGeneratorTitle: "AI post generator",
    postGeneratorDescription: "Your Ultimate AI Post Writing Assistant!",
    postGeneratorCanonicalUrl: "https://postly.ai/free-tools/post-generator",
    postGeneratorKeywords: ["Post Writer", "AI content generator", "free AI content creator"],
    postGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/post-generator",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "AI post generator",
        description: "Your Ultimate AI Post Writing Assistant!",
        url: "https://postly.ai/free-tools/post-generator",
        type: "website",
    },

    postGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    postGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    postGeneratorCategory: "Post Writer",
    postGeneratorTags: ["Post Writer", "AI content generator", "free AI content creator"],


    // 17 Blog Writer page 
    blogGeneratorTitle: "AI Blog Generator",
    blogGeneratorDescription: "AI Blog Writing Assistant!",
    blogGeneratorCanonicalUrl: "https://postly.ai/free-tools/blog-generator",
    blogGeneratorKeywords: ["AI blog writer", "blog generator", "blog templates", "blog writing", "blog editorial calendar", "scheduling software for bloggers", "content creation plan template", "content creation strategies", "content creation workflow", "Blog Writer", "AI content generator", "free AI content creator"],
    blogGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/blog-generator",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "AI Blog Generator",
        description: "AI Blog Writing Assistant!",
        url: "https://postly.ai/free-tools/blog-generator",
        type: "website",
    },

    blogGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    blogGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    blogGeneratorCategory: "Blog Writer",
    blogGeneratorTags: ["AI blog writer", "blog generator", "blog templates", "blog writing", "blog editorial calendar", "scheduling software for bloggers", "content creation plan template", "content creation strategies", "content creation workflow", "Blog Writer", "AI content generator", "free AI content creator"],

    // 19 Digital Ads Generator page 
    digitalAdsGeneratorTitle: "Digital Ads Generator",
    digitalAdsGeneratorDescription: "Unleash the future of advertising",
    digitalAdsGeneratorCanonicalUrl: "https://postly.ai/free-tools/digital-ads-generator",
    digitalAdsGeneratorKeywords: ["Digital ads writer", "AI content generator", "free AI content creator"],
    digitalAdsGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/digital-ads-generator",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Digital Ads Generator",
        description: "Unleash the future of advertising",
        url: "https://postly.ai/free-tools/digital-ads-generator",
        type: "website",
    },

    digitalAdsGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    digitalAdsGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    digitalAdsGeneratorCategory: "Digital Ads Writer",
    digitalAdsGeneratorTags: ["Digital ads writer", "AI content generator", "free AI content creator"],


    // 20 Media Library page 
    mediaLibraryTitle: "Media Library",
    mediaLibraryDescription: "Media Library, your Gateway to Creative Excellence!",
    mediaLibraryCanonicalUrl: "https://postly.ai/media-library",
    mediaLibraryKeywords: ["social media media library", "organize media assets", "free image library for social media", "media management tool", "access high-quality media", "media library for social content", "cloud storage", "affordable cloud storage and services", "cloud services for small businesses"],
    mediaLibraryAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/media-library",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Media Library",
        description: "Media Library, your Gateway to Creative Excellence!",
        url: "https://postly.ai/media-library",
        type: "website",
    },

    mediaLibraryPublishedDate: "2023-12-12T12:00:00Z",
    mediaLibraryModifiedDate: "2023-12-12T14:30:00Z",
    mediaLibraryCategory: "Media Library",
    mediaLibraryTags: ["social media media library", "organize media assets", "free image library for social media", "media management tool", "access high-quality media", "media library for social content", "cloud storage", "affordable cloud storage and services", "cloud services for small businesses"],

    // 21 Sales Copy Writer page 
    salesCopyWriterTitle: "Sales copy writer",
    salesCopyWriterDescription: "Sales copy writer",
    salesCopyWriterCanonicalUrl: "https://postly.ai/free-tools/sales-copy-writer",
    salesCopyWriterKeywords: ["Sales copy writer", "AI content generator", "free AI content creator"],
    salesCopyWriterAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/sales-copy-writer",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Sales copy writer",
        description: "Sales copy writer",
        url: "https://postly.ai/free-tools/sales-copy-writer",
        type: "website",
    },

    salesCopyWriterPublishedDate: "2023-12-12T12:00:00Z",
    salesCopyWriterModifiedDate: "2023-12-12T14:30:00Z",
    salesCopyWriterCategory: "Sales copy writer",
    salesCopyWriterTags: ["Sales copy writer", "AI content generator", "free AI content creator"],


    // 22 Social Inbox page 
    socialInboxTitle: "Social Inbox",
    socialInboxDescription: "Your Ultimate Social Inbox Tool!",
    socialInboxCanonicalUrl: "https://postly.ai/social-inbox",
    socialInboxKeywords: ["unified social inbox", "complete social inbox", "social inbox"],
    socialInboxAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/social-inbox",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Social Inbox",
        description: "Your Ultimate Social Inbox Tool!",
        url: "https://postly.ai/social-inbox",
        type: "website",
    },

    socialInboxPublishedDate: "2023-12-12T12:00:00Z",
    socialInboxModifiedDate: "2023-12-12T14:30:00Z",
    socialInboxCategory: "Social Inbox",
    socialInboxTags: ["unified social inbox", "complete social inbox", "social inbox"],


    // 25 Hashtag Generator page 
    hashtagGeneratorTitle: "AI-Powered Hashtag Generator for Social Media Success",
    hashtagGeneratorDescription: "Boost your visibility on Instagram, YouTube, and more with our free AI hashtag generator. Create trending and relevant hashtags in seconds!",
    hashtagGeneratorCanonicalUrl: "https://postly.ai/free-tools/hashtag-generator",
    hashtagGeneratorKeywords: ["AI hashtag generator for Instagram", "free YouTube hashtag generator", "social media hashtag tool", "AI-powered hashtag creator"],
    hashtagGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/hashtag-generator",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Generate Trending Hashtags with AI | Free Hashtag Generator",
        description: "Discover the ultimate tool for creating trending hashtags with ease. Perfect for Instagram, YouTube, and all social media platforms!",
        url: "https://postly.ai/free-tools/hashtag-generator",
        type: "website",
    },

    hashtagGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    hashtagGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    hashtagGeneratorCategory: "Hashtag Tools",
    hashtagGeneratorTags: ["AI hashtag generator", "free Instagram hashtags", "social media growth tools", "hashtag optimization", "free hashtag generator for YouTube"],


    // 26 Instagram Caption Generator page 
    instagramCaptionGeneratorTitle: "AI Instagram Caption Generator for Perfect Posts",
    instagramCaptionGeneratorDescription: "Create captivating Instagram captions effortlessly with our free AI caption generator. Maximize engagement with personalized suggestions!",
    instagramCaptionGeneratorCanonicalUrl: "https://postly.ai/free-tools/instagram-caption-generator",
    instagramCaptionGeneratorKeywords: ["AI Instagram caption generator", "free Instagram caption ideas", "best caption creator tool", "AI social media content generator"],
    instagramCaptionGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/instagram-caption-generator",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Create Engaging Instagram Captions with AI | Free Tool",
        description: "Enhance your Instagram posts with unique and engaging captions generated by AI. Try it now and watch your engagement soar!",
        url: "https://postly.ai/free-tools/instagram-caption-generator",
        type: "website",
    },

    instagramCaptionGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    instagramCaptionGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    instagramCaptionGeneratorCategory: "Social Media Tools",
    instagramCaptionGeneratorTags: ["Instagram caption generator", "free AI caption ideas", "best Instagram captions", "social media engagement tool", "AI caption creator"],


    // 27 Recurring Post page 
    recurringPostTitle: "Recurring post",
    recurringPostDescription: "Recurring Post: embrace the power of consistency",
    recurringPostCanonicalUrl: "https://postly.ai/recurring-post",
    recurringPostKeywords: ["recurring social media posts", "schedule recurring posts", "evergreen content scheduling", "automate recurring posts", "benefits of recurring posts", "manage recurring content", "schedule recurring post", "evergreen content", "repeat posts"],
    recurringPostAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/recurring-post",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Recurring post",
        description: "Recurring Post: embrace the power of consistency",
        url: "https://postly.ai/recurring-post",
        type: "website",
    },

    recurringPostPublishedDate: "2023-12-12T12:00:00Z",
    recurringPostModifiedDate: "2023-12-12T14:30:00Z",
    recurringPostCategory: "Recurring post",
    recurringPostTags: ["recurring social media posts", "schedule recurring posts", "evergreen content scheduling", "automate recurring posts", "benefits of recurring posts", "manage recurring content", "schedule recurring post", "evergreen content", "repeat posts"],


    // 28 Bulk Post page 
    bulkPostTitle: "Bulk post",
    bulkPostDescription: "Save time, post more with Bulk post",
    bulkPostCanonicalUrl: "https://postly.ai/bulk-post",
    bulkPostKeywords: ["bulk social media posting", "schedule posts in bulk", "bulk post scheduler", "save time with bulk posting", "accelerate social media growth with bulk posting", "efficient bulk social media management", "Bulk post social media campaigns", "Bulk schedule social media campaigns", "Bulk posting tools"],
    bulkPostAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/bulk-post",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Bulk post",
        description: "Save time, post more with Bulk post",
        url: "https://postly.ai/bulk-post",
        type: "website",
    },

    bulkPostPublishedDate: "2023-12-12T12:00:00Z",
    bulkPostModifiedDate: "2023-12-12T14:30:00Z",
    bulkPostCategory: "Bulk post",
    bulkPostTags: ["bulk social media posting", "schedule posts in bulk", "bulk post scheduler", "save time with bulk posting", "accelerate social media growth with bulk posting", "efficient bulk social media management", "Bulk post social media campaigns", "Bulk schedule social media campaigns", "Bulk posting tools"],


    // 29 Approval Workflow page 
    approvalWorkflowTitle: "Approval Workflow",
    approvalWorkflowDescription: "Elevate Your Social Media Game with Streamlined Approval",
    approvalWorkflowCanonicalUrl: "https://postly.ai/approval-workflow",
    approvalWorkflowKeywords: ["approval workflow for social media", "social media content approval process", "team collaboration for social media content", "content approval workflow", "streamline content approval", "social media collaboration tools", "approval system for social media posts", "enhance social media team productivity", "review social media posts", "social media approval workflow", "post approval process", "collaborate on social media content", "social media content approval", "social media post review", "Approval workflow", "workflow management", "team collaboration"],
    approvalWorkflowAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/approval-workflow",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Approval Workflow",
        description: "Elevate Your Social Media Game with Streamlined Approval",
        url: "https://postly.ai/approval-workflow",
        type: "website",
    },

    approvalWorkflowPublishedDate: "2023-12-12T12:00:00Z",
    approvalWorkflowModifiedDate: "2023-12-12T14:30:00Z",
    approvalWorkflowCategory: "Approval Workflow",
    approvalWorkflowTags: ["approval workflow for social media", "social media content approval process", "team collaboration for social media content", "content approval workflow", "streamline content approval", "social media collaboration tools", "approval system for social media posts", "enhance social media team productivity", "review social media posts", "social media approval workflow", "post approval process", "collaborate on social media content", "social media content approval", "social media post review", "Approval workflow", "workflow management", "team collaboration"],


    // 30 Analytics page 
    analyticsTitle: "Social Media Analytics",
    analyticsDescription: "Unleash the power of data with Postly Analytics!",
    analyticsCanonicalUrl: "https://postly.ai/analytics",
    analyticsKeywords: ["social media metrics", "measure social media growth", "social media insights", "analytics of Facebook", "Facebook analytics", "Instagram analytics", "LinkedIn analytics", "TikTok analytics", "YouTube analytics", "social media performance tracking", "social media analytics tools", "social media data analysis", "Social Media Analytics", "Best Social Media Analytics", "competitive analysis"],
    analyticsAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/analytics",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Social Media Analytics",
        description: "Unleash the power of data with Postly Analytics!",
        url: "https://postly.ai/analytics",
        type: "website",
    },

    analyticsPublishedDate: "2023-12-12T12:00:00Z",
    analyticsModifiedDate: "2023-12-12T14:30:00Z",
    analyticsCategory: "Social Media Analytics",
    analyticsTags: ["social media metrics", "measure social media growth", "social media insights", "analytics of Facebook", "Facebook analytics", "Instagram analytics", "LinkedIn analytics", "TikTok analytics", "YouTube analytics", "social media performance tracking", "social media analytics tools", "social media data analysis", "Social Media Analytics", "Best Social Media Analytics", "competitive analysis"],


    // 31 Help Center page 
    helpCenterTitle: "Help center",
    helpCenterDescription: "Postly Account support",
    helpCenterCanonicalUrl: "https://postly.ai/help-center",
    helpCenterKeywords: ["Social media help center", "Postly help resources", "Social media management help", "Get help with Postly", "Social media tool assistance", "Help resources for social media", "App for social media", "Apps for posting on social media", "Best day and time to post on social media", "How to make money on YouTube", "How to monetize YouTube", "How to monetize Facebook", "How to monetize TikTok", "How to monetize Instagram", "Ideal social media posting times", "Planned social media posts", "white label social media", "Using social media for marketing", "How to make money on Facebook", "How to make money on Instagram", "How to make money on TikTok", "How to make money on social media", "account support"],
    helpCenterAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/help-center",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Help center",
        description: "Postly Account support",
        url: "https://postly.ai/help-center",
        type: "website",
    },

    helpCenterPublishedDate: "2023-12-12T12:00:00Z",
    helpCenterModifiedDate: "2023-12-12T14:30:00Z",
    helpCenterCategory: "Help center",
    helpCenterTags: ["Social media help center", "Postly help resources", "Social media management help", "Get help with Postly", "Social media tool assistance", "Help resources for social media", "App for social media", "Apps for posting on social media", "Best day and time to post on social media", "How to make money on YouTube", "How to monetize YouTube", "How to monetize Facebook", "How to monetize TikTok", "How to monetize Instagram", "Ideal social media posting times", "Planned social media posts", "white label social media", "Using social media for marketing", "How to make money on Facebook", "How to make money on Instagram", "How to make money on TikTok", "How to make money on social media", "account support"],


    // 32 Demo Videos page 
    demoVideosTitle: "Demo Videos",
    demoVideosDescription: "Postly demo videos",
    demoVideosCanonicalUrl: "https://postly.ai/demo-videos",
    demoVideosKeywords: ["social media demo videos", "Getting started with social media management", "How to use Postly", "Social media scheduling demos", "Social media management tutorials", "Postly tutorial videos", "Video guides for social media tools", "demo videos, support"],
    demoVideosAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/demo-videos",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Demo Videos",
        description: "Postly demo videos",
        url: "https://postly.ai/demo-videos",
        type: "website",
    },

    demoVideosPublishedDate: "2023-12-12T12:00:00Z",
    demoVideosModifiedDate: "2023-12-12T14:30:00Z",
    demoVideosCategory: "Demo Videos",
    demoVideosTags: ["social media demo videos", "Getting started with social media management", "How to use Postly", "Social media scheduling demos", "Social media management tutorials", "Postly tutorial videos", "Video guides for social media tools", "demo videos, support"],


    // 34 Content format page 
    contentFormatTitle: "Content format",
    contentFormatDescription: "Social media content formats",
    contentFormatCanonicalUrl: "https://postly.ai/content-format",
    contentFormatKeywords: ["social media resources", "social media management tool", "social media platform resources", "social media content tools", "social media help resources", "social media content guides", "social media content formats", " content format guide", "best content format for social media", "optimizing content for social media", "content formatting best practices", "content formatting best practices", "social media content tips", "visual content formats for social media", "content creation plan template", "content creation strategies", "content creation workflow", "content marketing and digital marketing", "digital marketing and content marketing", "social media content creation services", "Social media content formats", "Content marketing", "social media content planner"],
    contentFormatAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/content-format",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Content format",
        description: "Social media content formats",
        url: "https://postly.ai/content-format",
        type: "website",
    },

    contentFormatPublishedDate: "2023-12-12T12:00:00Z",
    contentFormatModifiedDate: "2023-12-12T14:30:00Z",
    contentFormatCategory: "Content format",
    contentFormatTags: ["social media resources", "social media management tool", "social media platform resources", "social media content tools", "social media help resources", "social media content guides", "social media content formats", " content format guide", "best content format for social media", "optimizing content for social media", "content formatting best practices", "content formatting best practices", "social media content tips", "visual content formats for social media", "content creation plan template", "content creation strategies", "content creation workflow", "content marketing and digital marketing", "digital marketing and content marketing", "social media content creation services", "Social media content formats", "Content marketing", "social media content planner"],


    // 36 Terms of service page 
    termsOfServiceTitle: "Terms of service",
    termsOfServiceDescription: "Terms of service",
    termsOfServiceCanonicalUrl: "https://postly.ai/docs/terms-of-service",
    termsOfServiceKeywords: ["Postly terms of service", "legal"],
    termsOfServiceAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/docs/terms-of-service",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Terms of service",
        description: "Postly Terms of service",
        url: "https://postly.ai/docs/terms-of-service",
        type: "website",
    },

    termsOfServicePublishedDate: "2023-12-12T12:00:00Z",
    termsOfServiceModifiedDate: "2023-12-12T14:30:00Z",
    termsOfServiceCategory: "Terms of service",
    termsOfServiceTags: ["Postly terms of service", "legal"],


    // 37 Privacy policy page 
    privacyPolicyTitle: "Privacy policy",
    privacyPolicyDescription: "Privacy policy",
    privacyPolicyCanonicalUrl: "https://postly.ai/docs/privacy-policy",
    privacyPolicyKeywords: ["Privacy policy", "legal"],
    privacyPolicyAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/docs/privacy-policy",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Privacy policy",
        description: "Privacy policy",
        url: "https://postly.ai/docs/privacy-policy",
        type: "website",
    },

    privacyPolicyPublishedDate: "2023-12-12T12:00:00Z",
    privacyPolicyModifiedDate: "2023-12-12T14:30:00Z",
    privacyPolicyCategory: "Privacy policy",
    privacyPolicyTags: ["Privacy policy", "legal"],


    // 38 Cookie policy page 
    cookiePolicyTitle: "Cookie policy",
    cookiePolicyDescription: "Cookie policy",
    cookiePolicyCanonicalUrl: "https://postly.ai/docs/cookie-policy",
    cookiePolicyKeywords: ["Cookie policy", "legal"],
    cookiePolicyAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/docs/cookie-policy",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Cookie policy",
        description: "Cookie policy",
        url: "https://postly.ai/docs/cookie-policy",
        type: "website",
    },

    cookiePolicyPublishedDate: "2023-12-12T12:00:00Z",
    cookiePolicyModifiedDate: "2023-12-12T14:30:00Z",
    cookiePolicyCategory: "Cookie policy",
    cookiePolicyTags: ["Cookie policy", "legal"],


    // 39 GDPR compliance page 
    gdprComplianceTitle: "GDPR compliance",
    gdprComplianceDescription: "GDPR compliance",
    gdprComplianceCanonicalUrl: "https://postly.ai/docs/gdpr-compliance",
    gdprComplianceKeywords: ["GDPR compliance", "legal"],
    gdprComplianceAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/docs/gdpr-compliance",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "GDPR compliance",
        description: "GDPR compliance",
        url: "https://postly.ai/docs/gdpr-compliance",
        type: "website",
    },

    gdprCompliancePublishedDate: "2023-12-12T12:00:00Z",
    gdprComplianceModifiedDate: "2023-12-12T14:30:00Z",
    gdprComplianceCategory: "GDPR compliance",
    gdprComplianceTags: ["GDPR compliance", "legal"],


    // 40 Daily post limits page 
    dailyPostLimitsTitle: "Daily post limits",
    dailyPostLimitsDescription: "Daily post limits",
    dailyPostLimitsCanonicalUrl: "https://postly.ai/docs/daily-post-limits",
    dailyPostLimitsKeywords: ["Daily post limits", "legal"],
    dailyPostLimitsAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/docs/daily-post-limits",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Daily post limits",
        description: "Daily post limits",
        url: "https://postly.ai/docs/daily-post-limits",
        type: "website",
    },

    dailyPostLimitsPublishedDate: "2023-12-12T12:00:00Z",
    dailyPostLimitsModifiedDate: "2023-12-12T14:30:00Z",
    dailyPostLimitsCategory: "Daily post limits",
    dailyPostLimitsTags: ["Daily post limits", "legal"],


    // 41 FAQ page 
    faqTitle: "Frequently asked questions",
    faqDescription: "Frequently asked questions",
    faqCanonicalUrl: "https://postly.ai/faq",
    faqKeywords: ["Social media management FAQ", "Postly frequently asked questions", "Social media scheduling FAQ", "Social media platform questions", "Post automation FAQ", "Common social media questions", "FAQ for social media tools", "Frequently asked questions", "FAQ"],
    faqAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/faq",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Frequently asked questions",
        description: "Frequently asked questions",
        url: "https://postly.ai/faq",
        type: "website",
    },

    faqPublishedDate: "2023-12-12T12:00:00Z",
    faqModifiedDate: "2023-12-12T14:30:00Z",
    faqCategory: "Frequently asked questions",
    faqTags: ["Social media management FAQ", "Postly frequently asked questions", "Social media scheduling FAQ", "Social media platform questions", "Post automation FAQ", "Common social media questions", "FAQ for social media tools", "Frequently asked questions", "FAQ"],


    // 42 What's New page 
    whatsNewTitle: "What's new",
    whatsNewDescription: "What's new",
    whatsNewCanonicalUrl: "https://postly.ai/whats-new",
    whatsNewKeywords: ["What's new", "changelog", "product updates"],
    whatsNewAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/whats-new",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "What's new",
        description: "What's new",
        url: "https://postly.ai/whats-new",
        type: "website",
    },

    whatsNewPublishedDate: "2023-12-12T12:00:00Z",
    whatsNewModifiedDate: "2023-12-12T14:30:00Z",
    whatsNewCategory: "What's new",
    whatsNewTags: ["What's new", "changelog", "product updates"],

    // 43 Workspace page 
    workspaceTitle: "Workspace",
    workspaceDescription: "Workspace",
    workspaceCanonicalUrl: "https://postly.ai/workspace",
    workspaceKeywords: ["social media workspace", "organize social media activities", "workspace for content management", "social media workpace features", "manage social media campaigns", "Workspace", "Team collaboration", "brands"],
    workspaceAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/workspace",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Workspace",
        description: "Workspace",
        url: "https://postly.ai/workspace",
        type: "website",
    },

    workspacePublishedDate: "2023-12-12T12:00:00Z",
    workspaceModifiedDate: "2023-12-12T14:30:00Z",
    workspaceCategory: "Workspace",
    workspaceTags: ["social media workspace", "organize social media activities", "workspace for content management", "social media workpace features", "manage social media campaigns", "Workspace", "Team collaboration", "brands"],


    // 44 About us page 
    aboutTitle: "About us",
    aboutDescription: "About us",
    aboutCanonicalUrl: "https://postly.ai/about",
    aboutKeywords: ["About us", "About Postly", "Free social media scheduler"],
    aboutAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/about",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "About us",
        description: "About us",
        url: "https://postly.ai/about",
        type: "website",
    },

    aboutPublishedDate: "2023-12-12T12:00:00Z",
    aboutModifiedDate: "2023-12-12T14:30:00Z",
    aboutCategory: "About us",
    aboutTags: ["About us", "About Postly", "Free social media scheduler"],


    // 46 Link preview page 
    linkPreviewTitle: "Link preview",
    linkPreviewDescription: "Link preview",
    linkPreviewCanonicalUrl: "https://postly.ai/link-preview",
    linkPreviewKeywords: ["preview social media links", "link preview tool", "how to preview links before posting", "social media link preview", "preview urls for social media", "Link preview", "link management", "social media links"],
    linkPreviewAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/link-preview",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Link preview",
        description: "Link preview",
        url: "https://postly.ai/link-preview",
        type: "website",
    },

    linkPreviewPublishedDate: "2023-12-12T12:00:00Z",
    linkPreviewModifiedDate: "2023-12-12T14:30:00Z",
    linkPreviewCategory: "Link preview",
    linkPreviewTags: ["preview social media links", "link preview tool", "how to preview links before posting", "social media link preview", "preview urls for social media", "Link preview", "link management", "social media links"],


    // 47 Queue scheduler page 
    queueSchedulerTitle: "Queue scheduler",
    queueSchedulerDescription: "Queue scheduler for your social media campaigns",
    queueSchedulerCanonicalUrl: "https://postly.ai/queue-scheduler",
    queueSchedulerKeywords: ["Queue scheduler", "Post queues", "auto-post to social media"],
    queueSchedulerAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/queue-scheduler",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Queue scheduler",
        description: "Queue scheduler for your social media campaigns",
        url: "https://postly.ai/queue-scheduler",
        type: "website",
    },

    queueSchedulerPublishedDate: "2023-12-12T12:00:00Z",
    queueSchedulerModifiedDate: "2023-12-12T14:30:00Z",
    queueSchedulerCategory: "Queue scheduler",
    queueSchedulerTags: ["Queue scheduler", "Post queues", "auto-post to social media"],


    // 49 Businesses page 
    businessesTitle: "Small and medium businesses",
    businessesDescription: "Social media management for small and medium businesses",
    businessesCanonicalUrl: "https://postly.ai/solutions/businesses",
    businessesKeywords: ["ai for content creation", "ai social media manager", "content creation and social media", "content creation for marketing", "content marketing", "content marketing creator", "content strategies", "social media for business", "social media management for agencies", "social media management marketing", "Small and medium businesses", "small business tools", "social media marketing for SMEs"],
    businessesAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/solutions/businesses",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Small and medium businesses",
        description: "Social media management for small and medium businesses",
        url: "https://postly.ai/solutions/businesses",
        type: "website",
    },

    businessesPublishedDate: "2023-12-12T12:00:00Z",
    businessesModifiedDate: "2023-12-12T14:30:00Z",
    businessesCategory: "Small and medium businesses",
    businessesTags: ["ai for content creation", "ai social media manager", "content creation and social media", "content creation for marketing", "content marketing", "content marketing creator", "content strategies", "social media for business", "social media management for agencies", "social media management marketing", "Small and medium businesses", "small business tools", "social media marketing for SMEs"],


    // 50 Agencies page 
    agenciesTitle: "Social media management tools for agencies",
    agenciesDescription: "Social media management tools for agencies",
    agenciesCanonicalUrl: "https://postly.ai/solutions/agencies",
    agenciesKeywords: ["ai social media automation", "best social management tools", "marketing ai tools", "social media marketing plan", "social media management software", "white label social media dashboard", "Social media management tools for agencies", "all-in-one social media tool", "social media marketing for Agencies"],
    agenciesAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/solutions/agencies",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Social media management tools for agencies",
        description: "Social media management tools for agencies",
        url: "https://postly.ai/solutions/agencies",
        type: "website",
    },

    agenciesPublishedDate: "2023-12-12T12:00:00Z",
    agenciesModifiedDate: "2023-12-12T14:30:00Z",
    agenciesCategory: "Social media management tools for agencies",
    agenciesTags: ["ai social media automation", "best social management tools", "marketing ai tools", "social media marketing plan", "social media management software", "white label social media dashboard", "Social media management tools for agencies", "all-in-one social media tool", "social media marketing for Agencies"],


    // 51 Influencers page 
    influencersTitle: "Social media management tools for Creators and influencers",
    influencersDescription: "Social media management tools for Creators and influencers",
    influencersCanonicalUrl: "https://postly.ai/solutions/influencers",
    influencersKeywords: ["AI for social media posts", "ai writer", "best influencer apps", "influencer marketing campaign", "influencer marketing strategy", "instagram strategies", "instagram content calendar", "instagram post scheduler", "Social media management tools for Creators and influencers", "all-in-one social media tool", "social media marketing for influencers"],
    influencersAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/solutions/influencers",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Social media management tools for Creators and influencers",
        description: "Social media management tools for Creators and influencers",
        url: "https://postly.ai/solutions/influencers",
        type: "website",
    },

    influencersPublishedDate: "2023-12-12T12:00:00Z",
    influencersModifiedDate: "2023-12-12T14:30:00Z",
    influencersCategory: "Social media management tools for Creators and influencers",
    influencersTags: ["AI for social media posts", "ai writer", "best influencer apps", "influencer marketing campaign", "influencer marketing strategy", "instagram strategies", "instagram content calendar", "instagram post scheduler", "Social media management tools for Creators and influencers", "all-in-one social media tool", "social media marketing for influencers"],


    // 52 Social media managers and freelancers page 
    socialMediaManagersTitle: "Social media managers and freelancers",
    socialMediaManagersDescription: "Social media managers and freelancers",
    socialMediaManagersCanonicalUrl: "https://postly.ai/solutions/social-media-managers",
    socialMediaManagersKeywords: ["ai social media manager", "automated newsletter system", "best social management tools", "social media management software", "social media metrics", "social media management content creation", "social media posting tool", "social media workflow tools", "social media scheduling software", "social media scheduling tools", "Social media managers and freelancers", "all-in-one social media tool", "social media marketing for freelancers"],
    socialMediaManagersAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/solutions/social-media-managers",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Social media managers and freelancers",
        description: "Social media managers and freelancers",
        url: "https://postly.ai/solutions/social-media-managers",
        type: "website",
    },

    socialMediaManagersPublishedDate: "2023-12-12T12:00:00Z",
    socialMediaManagersModifiedDate: "2023-12-12T14:30:00Z",
    socialMediaManagersCategory: "Social media managers and freelancers",
    socialMediaManagersTags: ["ai social media manager", "automated newsletter system", "best social management tools", "social media management software", "social media metrics", "social media management content creation", "social media posting tool", "social media workflow tools", "social media scheduling software", "social media scheduling tools", "Social media managers and freelancers", "all-in-one social media tool", "social media marketing for freelancers"],


    // 53 Security page 
    securityTitle: "Security",
    securityDescription: "Security",
    securityCanonicalUrl: "https://postly.ai/security",
    securityKeywords: ["Security", "secure application for social media marketing", "internet security"],
    securityAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/security",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Security",
        description: "Security",
        url: "https://postly.ai/security",
        type: "website",
    },

    securityPublishedDate: "2023-12-12T12:00:00Z",
    securityModifiedDate: "2023-12-12T14:30:00Z",
    securityCategory: "Security",
    securityTags: ["Security", "secure application for social media marketing", "internet security"],


    // 54 Postly Cloud page 
    postlyCloudTitle: "Postly Cloud services",
    postlyCloudDescription: "Postly Cloud services, your Gateway to Creative Excellence!",
    postlyCloudCanonicalUrl: "https://postly.ai/postly-cloud",
    postlyCloudKeywords: ["centralized media storage", "organize media with postly cloud", "cloud storage for social media assets", "manage media assets in the cloud", "postly cloud features", "cloud storage", "affordable cloud storage and services", "cloud services for small businesses"],
    postlyCloudAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/postly-cloud",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Cloud services",
        description: "Postly Cloud services, your Gateway to Creative Excellence!",
        url: "https://postly.ai/postly-cloud",
        type: "website",
    },

    postlyCloudPublishedDate: "2023-12-12T12:00:00Z",
    postlyCloudModifiedDate: "2023-12-12T14:30:00Z",
    postlyCloudCategory: "Cloud services",
    postlyCloudTags: ["centralized media storage", "organize media with postly cloud", "cloud storage for social media assets", "manage media assets in the cloud", "postly cloud features", "cloud storage", "affordable cloud storage and services", "cloud services for small businesses"],

    // 58 How it works page 
    howItWorksTitle: "How Postly works, how to connect an account, how to schedule a post",
    howItWorksDescription: "How Postly works, how to connect an account, how to schedule a post",
    howItWorksCanonicalUrl: "https://postly.ai/how-it-works",
    howItWorksKeywords: ["Social media management walkthrough", "Social media management features", "How to automate social media posts", "Postly user guide", "How Postly works, how to connect an account, how to schedule a post"],
    howItWorksAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/how-it-works",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "How Postly works, how to connect an account, how to schedule a post",
        description: "How Postly works, how to connect an account, how to schedule a post",
        url: "https://postly.ai/how-it-works",
        type: "website",
    },

    howItWorksPublishedDate: "2023-12-12T12:00:00Z",
    howItWorksModifiedDate: "2023-12-12T14:30:00Z",
    howItWorksCategory: "How Postly works",
    howItWorksTags: ["Social media management walkthrough", "Social media management features", "How to automate social media posts", "Postly user guide", "How Postly works, how to connect an account, how to schedule a post"],


    // 59 Facebook Carousel platform page 
    facebookCarouselTitle: "Schedule Facebook Carousel posts",
    facebookCarouselDescription: "Schedule Facebook Carousel posts",
    facebookCarouselCanonicalUrl: "https://postly.ai/facebook-carousel",
    facebookCarouselKeywords: ["facebook carousel posts", "facebook carousel campaign", "best practices for facebook carousel", "facebook carousel feature", "facebook marketing with carousels", "carousel posts for facebook", "schedule post on facebook", "auto-post to facebook", "grow your business on facebook", "auto-post to facebook", "Schedule Facebook Carousel posts"],
    facebookCarouselAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/facebook-carousel",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Schedule Facebook Carousel posts",
        description: "Schedule Facebook Carousel posts",
        url: "https://postly.ai/facebook-carousel",
        type: "website",
    },

    facebookCarouselPublishedDate: "2023-12-12T12:00:00Z",
    facebookCarouselModifiedDate: "2023-12-12T14:30:00Z",
    facebookCarouselCategory: "Schedule Facebook Carousel posts",
    facebookCarouselTags: ["facebook carousel posts", "facebook carousel campaign", "best practices for facebook carousel", "facebook carousel feature", "facebook marketing with carousels", "carousel posts for facebook", "schedule post on facebook", "auto-post to facebook", "grow your business on facebook", "auto-post to facebook", "Schedule Facebook Carousel posts"],


    // 60 RSS Feed page
    rssFeedTitle: "RSS Feed post scheduler",
    rssFeedDescription: "RSS Feed post scheduler",
    rssFeedCanonicalUrl: "https://postly.ai/rss-feed",
    rssFeedKeywords: ["schedule RSS Feed posts", "RSS Feed"],
    rssFeedAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/rss-feed",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "RSS Feed post scheduler",
        description: "RSS Feed post scheduler",
        url: "https://postly.ai/rss-feed",
        type: "website",
    },

    rssFeedPublishedDate: "2023-12-12T12:00:00Z",
    rssFeedModifiedDate: "2023-12-12T14:30:00Z",
    rssFeedCategory: "RSS Feed post scheduler",
    rssFeedTags: ["schedule RSS Feed posts", "RSS Feed"],


    // 61 Audience Groups page
    audienceGroupsTitle: "Create audience groups",
    audienceGroupsDescription: "Create audience groups",
    audienceGroupsCanonicalUrl: "https://postly.ai/audience-groups",
    audienceGroupsKeywords: ["organize social media audience groups", "social media audience management", "audience segmentation in social media", "manage social media audience", "social media group organization", "target audience groups for social media", "Create audience groups", "schedule posts on linkedin and beyond"],
    audienceGroupsAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/audience-groups",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Create audience groups",
        description: "Create audience groups",
        url: "https://postly.ai/audience-groups",
        type: "website",
    },

    audienceGroupsPublishedDate: "2023-12-12T12:00:00Z",
    audienceGroupsModifiedDate: "2023-12-12T14:30:00Z",
    audienceGroupsCategory: "Create audience groups",
    audienceGroupsTags: ["organize social media audience groups", "social media audience management", "audience segmentation in social media", "manage social media audience", "social media group organization", "target audience groups for social media", "Create audience groups", "schedule posts on linkedin and beyond"],


    // 62 Post Preview page
    postPreviewTitle: "Post preview",
    postPreviewDescription: "Post preview",
    postPreviewCanonicalUrl: "https://postly.ai/post-preview",
    postPreviewKeywords: ["preview social media posts", "post preview tool", "how to preview posts before publishing", "social media post preview", "optimize post appearance", "Post preview", "schedule posts on linkedin and beyond"],
    postPreviewAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/post-preview",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Post preview",
        description: "Post preview",
        url: "https://postly.ai/post-preview",
        type: "website",
    },

    postPreviewPublishedDate: "2023-12-12T12:00:00Z",
    postPreviewModifiedDate: "2023-12-12T14:30:00Z",
    postPreviewCategory: "Post preview",
    postPreviewTags: ["preview social media posts", "post preview tool", "how to preview posts before publishing", "social media post preview", "optimize post appearance", "Post preview", "schedule posts on linkedin and beyond"],


    // 63 Republish Posts page
    republishPostsTitle: "Republish your already published posts",
    republishPostsDescription: "Republish your already published posts",
    republishPostsCanonicalUrl: "https://postly.ai/republish-posts",
    republishPostsKeywords: ["republish social media posts", "repurpose published content", "republish content on social media", "duplicate and reschedule posts", "manage republished content", "Republish posts", "schedule posts on linkedin and beyond"],
    republishPostsAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/republish-posts",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Republish your already published posts",
        description: "Republish your already published posts",
        url: "https://postly.ai/republish-posts",
        type: "website",
    },

    republishPostsPublishedDate: "2023-12-12T12:00:00Z",
    republishPostsModifiedDate: "2023-12-12T14:30:00Z",
    republishPostsCategory: "Republish posts",
    republishPostsTags: ["republish social media posts", "repurpose published content", "republish content on social media", "duplicate and reschedule posts", "manage republished content", "Republish posts", "schedule posts on linkedin and beyond"],


    // 66 Blogger page
    bloggerTitle: "Publish articles on blogger.com",
    bloggerDescription: "Publish articles on blogger.com",
    bloggerCanonicalUrl: "https://postly.ai/blogger",
    bloggerKeywords: ["Blogger post scheduler", "Automated Blogger posts", "Blogger content management", "Blogger scheduling tool", "Publish articles on blogger.com", "schedule blogs on blogger.com"],
    bloggerAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/blogger",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Publish articles on blogger.com",
        description: "Publish articles on blogger.com",
        url: "https://postly.ai/blogger",
        type: "website",
    },

    bloggerPublishedDate: "2023-12-12T12:00:00Z",
    bloggerModifiedDate: "2023-12-12T14:30:00Z",
    bloggerCategory: "Publish articles on blogger.com",
    bloggerTags: ["Blogger post scheduler", "Automated Blogger posts", "Blogger content management", "Blogger scheduling tool", "Publish articles on blogger.com", "schedule blogs on blogger.com"],


    // 68 WordPress page
    wordPressTitle: "Publish blog posts to WordPress sites.",
    wordPressDescription: "Publish blog posts to WordPress sites.",
    wordPressCanonicalUrl: "https://postly.ai/wordpress",
    wordPressKeywords: ["Wordpress blog scheduling tool", "Schedule WordPress blog posts", "Blogging automation tools", "Blog post scheduling software", "WordPress content scheduling", "Social media tools for WordPress bloggers", "Publish blog posts", "WordPress publishing made easy"],
    wordPressAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/wordpress",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Publish blog posts to WordPress sites.",
        description: "Publish blog posts to WordPress sites.",
        url: "https://postly.ai/wordpress",
        type: "website",
    },

    wordPressPublishedDate: "2023-12-12T12:00:00Z",
    wordPressModifiedDate: "2023-12-12T14:30:00Z",
    wordPressCategory: "Publish blog posts to WordPress sites.",
    wordPressTags: ["Wordpress blog scheduling tool", "Schedule WordPress blog posts", "Blogging automation tools", "Blog post scheduling software", "WordPress content scheduling", "Social media tools for WordPress bloggers", "Publish blog posts", "WordPress publishing made easy"],


    // 70 Link Shortener page
    linkShortenerTitle: "Link shortener",
    linkShortenerDescription: "Elevate Your links with Postly’s Link Shortener",
    linkShortenerCanonicalUrl: "https://postly.ai/free-tools/link-shortener",
    linkShortenerKeywords: ["url shortener tool", "shorten long urls", "link shortenening for social media", "create short links", "url shortenening service", "manage short links", "Link shortener", "free url shortener", "short URLs"],
    linkShortenerAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/link-shortener",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Link shortener",
        description: "Elevate Your links with Postly’s Link Shortener",
        url: "https://postly.ai/free-tools/link-shortener",
        type: "website",
    },

    linkShortenerPublishedDate: "2023-12-12T12:00:00Z",
    linkShortenerModifiedDate: "2023-12-12T14:30:00Z",
    linkShortenerCategory: "Link shortener",
    linkShortenerTags: ["url shortener tool", "shorten long urls", "link shortenening for social media", "create short links", "url shortenening service", "manage short links", "Link shortener", "free url shortener", "short URLs"],


    // 71 Campaign URL Builder page
    campaignURLBuilderTitle: "Campaign URL builder",
    campaignURLBuilderDescription: "Best tools to boost your campaigns · Supercharge Your Campaigns with Our Dynamic URL Builder! · Custom campaign URLs · Effortless tracking · Clear attribution.",
    campaignURLBuilderCanonicalUrl: "https://postly.ai/free-tools/campaign-url-builder",
    campaignURLBuilderKeywords: ["UTM builder", "campaign url builder tool", "generate utm links", "track social media campaigns", "url builder for campaigns", "create trackable urls", "Campaign URL builder", "free UTM builder", "UTM Links", "UTM builder Google"],
    campaignURLBuilderAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/campaign-url-builder",  // Ensure this matches the canonical URL
        },
    },

    og: {
        title: "Campaign URL builder",
        description: "Best tools to boost your campaigns · Supercharge Your Campaigns with Our Dynamic URL Builder! · Custom campaign URLs · Effortless tracking · Clear attribution.",
        url: "https://postly.ai/free-tools/campaign-url-builder",
        type: "website",
    },

    campaignURLBuilderPublishedDate: "2023-12-12T12:00:00Z",
    campaignURLBuilderModifiedDate: "2023-12-12T14:30:00Z",
    campaignURLBuilderCategory: "Campaign URL builder",
    campaignURLBuilderTags: ["UTM builder", "campaign url builder tool", "generate utm links", "track social media campaigns", "url builder for campaigns", "create trackable urls", "Campaign URL builder", "free UTM builder", "UTM Links", "UTM builder Google"],


    // 83 White Label
    whiteLabelTitle: "White Label solution",
    whiteLabelDescription: "White label solution",
    whiteLabelCanonicalUrl: "https://postly.ai/white-label",
    whiteLabelKeywords: ["white-label social media management", "customizable social media tools", "branded social media platform", "white-label social media dashboard", "white-label solution for social media", "White label solution", "social media scheduler", "social media management"],
    whiteLabelAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/white-label",
        },
    },

    og: {
        title: "White Label solution",
        description: "White Label solution",
        url: "https://postly.ai/white-label",
        type: "website",
    },

    whiteLabelPublishedDate: "2023-12-12T12:00:00Z",
    whiteLabelModifiedDate: "2023-12-12T14:30:00Z",
    whiteLabelCategory: "White Label solution",
    whiteLabelTags: ["white-label social media management", "customizable social media tools", "branded social media platform", "white-label social media dashboard", "white-label solution for social media", "White label solution", "social media scheduler", "social media management"],

    // 97 Blog SEO Meta Description Generator
    blogSEOMetaDescriptionGeneratorTitle: "Free AI Blog SEO Meta Description generator",
    blogSEOMetaDescriptionGeneratorDescription: "Free AI Blog SEO Meta Description generator",
    blogSEOMetaDescriptionGeneratorCanonicalUrl: "https://postly.ai/free-tools/blog-seo-meta-description-generator",
    blogSEOMetaDescriptionGeneratorKeywords: ["Free AI Blog SEO Meta Description generator"],
    blogSEOMetaDescriptionGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/blog-seo-meta-description-generator",
        },
    },

    og: {
        title: "Free AI Blog SEO Meta Description generator",
        description: "Free AI Blog SEO Meta Description generator",
        url: "https://postly.ai/free-tools/blog-seo-meta-description-generator",
        type: "website",
    },

    blogSEOMetaDescriptionGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    blogSEOMetaDescriptionGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    blogSEOMetaDescriptionGeneratorCategory: "Free AI Blog SEO Meta Description generator",
    blogSEOMetaDescriptionGeneratorTags: ["Free AI Blog SEO Meta Description generator"],

    // 99 Sales Copy Writer
    salesCopyWriterTitle: "Free AI Sales Copy Writer",
    salesCopyWriterDescription: "Free AI Sales Copy Writer",
    salesCopyWriterCanonicalUrl: "https://postly.ai/free-tools/sales-copy-writer",
    salesCopyWriterKeywords: ["Free AI Sales Copy Writer"],
    salesCopyWriterAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/sales-copy-writer",
        },
    },

    og: {
        title: "Free AI Sales Copy Writer",
        description: "Free AI Sales Copy Writer",
        url: "https://postly.ai/free-tools/sales-copy-writer",
        type: "website",
    },

    salesCopyWriterPublishedDate: "2023-12-12T12:00:00Z",
    salesCopyWriterModifiedDate: "2023-12-12T14:30:00Z",
    salesCopyWriterCategory: "Free AI Sales Copy Writer",
    salesCopyWriterTags: ["Free AI Sales Copy Writer"],

    // 100 Meme Generator
    memeGeneratorTitle: "Free AI Meme Generator",
    memeGeneratorDescription: "Free AI Meme Generator",
    memeGeneratorCanonicalUrl: "https://postly.ai/free-tools/meme-generator",
    memeGeneratorKeywords: ["Free AI Meme Generator"],
    memeGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/meme-generator",
        },
    },

    og: {
        title: "Free AI Meme Generator",
        description: "Free AI Meme Generator",
        url: "https://postly.ai/free-tools/meme-generator",
        type: "website",
    },

    memeGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    memeGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    memeGeneratorCategory: "Free AI Meme Generator",
    memeGeneratorTags: ["Free AI Meme Generator"],

    // 101 Color Palette Generator
    colorPaletteGeneratorTitle: "Free AI Color Palette generator",
    colorPaletteGeneratorDescription: "Free AI Color Palette generator",
    colorPaletteGeneratorCanonicalUrl: "https://postly.ai/free-tools/color-palette-generator",
    colorPaletteGeneratorKeywords: ["Free AI Color Palette generator"],
    colorPaletteGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/color-palette-generator",
        },
    },

    og: {
        title: "Free AI Color Palette generator",
        description: "Free AI Color Palette generator",
        url: "https://postly.ai/free-tools/color-palette-generator",
        type: "website",
    },

    colorPaletteGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    colorPaletteGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    colorPaletteGeneratorCategory: "Free AI Color Palette generator",
    colorPaletteGeneratorTags: ["Free AI Color Palette generator"],

    // 102 Best Photo Picker
    bestPhotoPickerTitle: "Free AI Best photo picker",
    bestPhotoPickerDescription: "Free AI Best photo picker",
    bestPhotoPickerCanonicalUrl: "https://postly.ai/free-tools/best-photo-picker",
    bestPhotoPickerKeywords: ["Free AI Best photo picker"],
    bestPhotoPickerAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/best-photo-picker",
        },
    },

    og: {
        title: "Free AI Best photo picker",
        description: "Free AI Best photo picker",
        url: "https://postly.ai/free-tools/best-photo-picker",
        type: "website",
    },

    bestPhotoPickerPublishedDate: "2023-12-12T12:00:00Z",
    bestPhotoPickerModifiedDate: "2023-12-12T14:30:00Z",
    bestPhotoPickerCategory: "Free AI Best photo picker",
    bestPhotoPickerTags: ["Free AI Best photo picker"],

    // 103 Video Thumbnail Picker
    videoThumbnailPickerTitle: "Free AI Video thumbnail picker",
    videoThumbnailPickerDescription: "Free AI Video thumbnail picker",
    videoThumbnailPickerCanonicalUrl: "https://postly.ai/free-tools/video-thumbnail-picker",
    videoThumbnailPickerKeywords: ["Free AI Video thumbnail picker"],
    videoThumbnailPickerAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/video-thumbnail-picker",
        },
    },

    og: {
        title: "Free AI Video thumbnail picker",
        description: "Free AI Video thumbnail picker",
        url: "https://postly.ai/free-tools/video-thumbnail-picker",
        type: "website",
    },

    videoThumbnailPickerPublishedDate: "2023-12-12T12:00:00Z",
    videoThumbnailPickerModifiedDate: "2023-12-12T14:30:00Z",
    videoThumbnailPickerCategory: "Free AI Video thumbnail picker",
    videoThumbnailPickerTags: ["Free AI Video thumbnail picker"],

    // 104 Background Remover
    backgroundRemoverTitle: "Free AI Background remover",
    backgroundRemoverDescription: "Free AI Background remover",
    backgroundRemoverCanonicalUrl: "https://postly.ai/free-tools/background-remover",
    backgroundRemoverKeywords: ["Free AI Background remover"],
    backgroundRemoverAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/background-remover",
        },
    },

    og: {
        title: "Free AI Background remover",
        description: "Free AI Background remover",
        url: "https://postly.ai/free-tools/background-remover",
        type: "website",
    },

    backgroundRemoverPublishedDate: "2023-12-12T12:00:00Z",
    backgroundRemoverModifiedDate: "2023-12-12T14:30:00Z",
    backgroundRemoverCategory: "Free AI Background remover",
    backgroundRemoverTags: ["Free AI Background remover"],

    // 105 Background Color Changer
    backgroundColorChangerTitle: "Free AI Background color changer",
    backgroundColorChangerDescription: "Free AI Background color changer",
    backgroundColorChangerCanonicalUrl: "https://postly.ai/free-tools/background-color-changer",
    backgroundColorChangerKeywords: ["Free AI Background color changer"],
    backgroundColorChangerAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/background-color-changer",
        },
    },

    og: {
        title: "Free AI Background color changer",
        description: "Free AI Background color changer",
        url: "https://postly.ai/free-tools/background-color-changer",
        type: "website",
    },

    backgroundColorChangerPublishedDate: "2023-12-12T12:00:00Z",
    backgroundColorChangerModifiedDate: "2023-12-12T14:30:00Z",
    backgroundColorChangerCategory: "Free AI Background color changer",
    backgroundColorChangerTags: ["Free AI Background color changer"],

    // 106 Stock Image Finder
    stockImageFinderTitle: "Free AI Stock image finder",
    stockImageFinderDescription: "Free AI Stock image finder",
    stockImageFinderCanonicalUrl: "https://postly.ai/free-tools/stock-image-finder",
    stockImageFinderKeywords: ["Free AI Stock image finder"],
    stockImageFinderAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/stock-image-finder",
        },
    },

    og: {
        title: "Free AI Stock image finder",
        description: "Free AI Stock image finder",
        url: "https://postly.ai/free-tools/stock-image-finder",
        type: "website",
    },

    stockImageFinderPublishedDate: "2023-12-12T12:00:00Z",
    stockImageFinderModifiedDate: "2023-12-12T14:30:00Z",
    stockImageFinderCategory: "Free AI Stock image finder",
    stockImageFinderTags: ["Free AI Stock image finder"],

    // 107 Video Caption Generator
    videoCaptionGeneratorTitle: "Free AI Video captions generator",
    videoCaptionGeneratorDescription: "Free AI Video captions generator",
    videoCaptionGeneratorCanonicalUrl: "https://postly.ai/free-tools/video-caption-generator",
    videoCaptionGeneratorKeywords: ["Free AI Video captions generator, AI caption generator"],
    videoCaptionGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/video-caption-generator",
        },
    },

    og: {
        title: "Free AI Video caption generator",
        description: "Free AI Video caption generator",
        url: "https://postly.ai/free-tools/video-caption-generator",
        type: "website",
    },

    videoCaptionGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    videoCaptionGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    videoCaptionGeneratorCategory: "Free AI Video caption generator",
    videoCaptionGeneratorTags: ["Free AI Video caption generator, AI caption generator"],

    // 108 Video Idea Generator
    videoIdeaGeneratorTitle: "Free AI Video Idea Generator",
    videoIdeaGeneratorDescription: "Free AI Video Idea Generator",
    videoIdeaGeneratorCanonicalUrl: "https://postly.ai/free-tools/video-idea-generator",
    videoIdeaGeneratorKeywords: ["Free AI Video Ideas Generator"],
    videoIdeaGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/video-idea-generator",
        },
    },

    og: {
        title: "Free AI Video Idea Generator",
        description: "Free AI Video Idea Generator",
        url: "https://postly.ai/free-tools/video-idea-generator",
        type: "website",
    },

    videoIdeaGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    videoIdeaGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    videoIdeaGeneratorCategory: "Free AI Video Ideas Generator",
    videoIdeaGeneratorTags: ["Free AI Video Ideas Generator"],

    // 109 Video Call To Action Generator
    videoCallToActionGeneratorTitle: "Free AI Video Call To Action Generator",
    videoCallToActionGeneratorDescription: "Free AI Video Call To Action Generator",
    videoCallToActionGeneratorCanonicalUrl: "https://postly.ai/free-tools/video-call-to-action-generator",
    videoCallToActionGeneratorKeywords: ["Free AI Video Call To Action Generator"],
    videoCallToActionGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/video-call-to-action-generator",
        },
    },

    og: {
        title: "Free AI Video Call To Action Generator",
        description: "Video Call To Action Generator",
        url: "https://postly.ai/free-tools/video-call-to-action-generator",
        type: "website",
    },

    videoCallToActionGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    videoCallToActionGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    videoCallToActionGeneratorCategory: "Free AI Video Call To Action Generator",
    videoCallToActionGeneratorTags: ["Free AI Video Call To Action Generator"],

    // 110 Postly Affiliate Program
    affiliateTitle: "Postly affiliate program",
    affiliateDescription: "Postly affiliate program",
    affiliateCanonicalUrl: "https://postly.ai/affiliate",
    affiliateKeywords: ["Postly affiliate program"],
    affiliateAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/affiliate",
        },
    },

    og: {
        title: "Postly affiliate program",
        description: "Postly affiliate program",
        url: "https://postly.ai/affiliate",
        type: "website",
    },

    affiliatePublishedDate: "2023-12-12T12:00:00Z",
    affiliateModifiedDate: "2023-12-12T14:30:00Z",
    affiliateCategory: "Postly affiliate program",
    affiliateTags: ["Postly affiliate program"],

    // 111 Postly mobile app
    postlyMobileAppTitle: "Share post",
    postlyMobileAppDescription: "Share post to facebook groups and more",
    postlyMobileAppCanonicalUrl: "https://postly.ai/postly-mobile-app",
    postlyMobileAppKeywords: ["Share post to facebook groups and more"],
    postlyMobileAppAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/postly-mobile-app",
        },
    },

    og: {
        title: "Share post to facebook groups and more",
        description: "Share post to facebook groups and more",
        url: "https://postly.ai/postly-mobile-app",
        type: "website",
    },

    postlyMobileAppPublishedDate: "2023-12-12T12:00:00Z",
    postlyMobileAppModifiedDate: "2023-12-12T14:30:00Z",
    postlyMobileAppCategory: "Share post to facebook groups and more",
    postlyMobileAppTags: ["Share post to facebook groups and more"],

    // 112 Integrations
    integrationsTitle: "Social Media Publisher Integrations for Postly",
    integrationsDescription: "Effortlessly share and schedule posts across Facebook groups, pages, and more using Postly's powerful publishing tools.",
    integrationsCanonicalUrl: "https://postly.ai/integrations",
    integrationsKeywords: ["ai social media manager", "marketing automation newsletter", "social media management tools", "integrations with social media platforms", "Postly social media integrations", "Share posts to Facebook groups", "Social media automation tools", "Schedule posts on Facebook", "Cross-platform social media publishing"],
    integrationsAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/integrations",
        },
    },

    og: {
        title: "Social Media Publisher Integrations for Postly",
        description: "Maximize your social media reach by sharing posts to Facebook groups, pages, and other platforms with Postly's integrations.",
        url: "https://postly.ai/integrations",
        type: "website",
    },

    integrationsPublishedDate: "2023-12-12T12:00:00Z",
    integrationsModifiedDate: "2023-12-12T14:30:00Z",
    integrationsCategory: "Social Media Publishing",
    integrationsTags: ["ai social media manager", "marketing automation newsletter", "social media management tools", "integrations with social media platforms", "Social media integrations", "Facebook group posting", "Social media scheduling", "Cross-platform publishing", "Social media automation"],

    // 113 Reviews
    reviewsTitle: "User Reviews for Postly - Social Media Publishing Tools",
    reviewsDescription: "See what our users are saying about Postly's powerful social media publishing and scheduling tools.",
    reviewsCanonicalUrl: "https://postly.ai/reviews",
    reviewsKeywords: ["Postly reviews", "Social media publishing reviews", "Postly user testimonials", "Postly customer feedback", "Social media automation reviews"],
    reviewsAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/reviews",
        },
    },

    og: {
        title: "User Reviews for Postly - Social Media Publishing Tools",
        description: "Read real user testimonials and reviews about Postly's social media publishing and scheduling features.",
        url: "https://postly.ai/reviews",
        type: "website",
    },

    reviewsPublishedDate: "2023-12-12T12:00:00Z",
    reviewsModifiedDate: "2023-12-12T14:30:00Z",
    reviewsCategory: "User Testimonials",
    reviewsTags: ["Postly reviews", "Social media publishing reviews", "Customer feedback", "User testimonials", "Social media automation reviews"],

    // 115 Snapchat Platform Page
    snapchatTitle: "Schedule Posts on Snapchat",
    snapchatDescription: "Auto-post stories and snaps to Snapchat.",
    snapchatCanonicalUrl: "https://postly.ai/snapchat",
    snapchatKeywords: ["schedule posts on snapchat", "snapchat post scheduler", "snapchat post automation", "automated snapchat posts", "snapchat content scheduling", "best times to post on snapchat", "snapchat scheduling tool", "Auto-post to Snapchat", "Grow on Snapchat"],
    snapchatAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/snapchat",
        },
    },

    og: {
        title: "Schedule Posts on Snapchat",
        description: "Auto-post stories and snaps to Snapchat.",
        url: "https://postly.ai/snapchat",
        type: "website",
    },

    snapchatPublishedDate: "2023-12-12T12:00:00Z",
    snapchatModifiedDate: "2023-12-12T14:30:00Z",
    snapchatCategory: "Snapchat publishing",
    snapchatTags: ["schedule posts on snapchat", "snapchat post scheduler", "snapchat post automation", "automated snapchat posts", "snapchat content scheduling", "best times to post on snapchat", "snapchat scheduling tool", "Auto-post to Snapchat", "Grow on Snapchat"],

    // 116 Bluesky Platform Page
    blueskyTitle: "Schedule Posts on Bluesky",
    blueskyDescription: "Auto-post updates and content to Bluesky.",
    blueskyCanonicalUrl: "https://postly.ai/bluesky",
    blueskyKeywords: ["schedule posts on bluesky", "bluesky post scheduler", "bluesky post automation", "automated bluesky posts", "bluesky content scheduling", "best times to post on bluesky", "bluesky scheduling tool", "Auto-post to Bluesky", "Grow on Bluesky"],
    blueskyAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/bluesky",
        },
    },

    og: {
        title: "Schedule Posts on Bluesky",
        description: "Auto-post updates and content to Bluesky.",
        url: "https://postly.ai/bluesky",
        type: "website",
    },

    blueskyPublishedDate: "2023-12-12T12:00:00Z",
    blueskyModifiedDate: "2023-12-12T14:30:00Z",
    blueskyCategory: "Bluesky publishing",
    blueskyTags: ["schedule posts on bluesky", "bluesky post scheduler", "bluesky post automation", "automated bluesky posts", "bluesky content scheduling", "best times to post on bluesky", "bluesky scheduling tool", "Auto-post to Bluesky", "Grow on Bluesky"],

    // 117 Tumblr Platform Page
    tumblrTitle: "Schedule Posts on Tumblr",
    tumblrDescription: "Auto-post text, images, and links to Tumblr.",
    tumblrCanonicalUrl: "https://postly.ai/tumblr",
    tumblrKeywords: ["schedule posts on tumblr", "tumblr post scheduler", "tumblr post automation", "automated tumblr posts", "tumblr content scheduling", "best times to post on tumblr", "tumblr scheduling tool", "Auto-post to Tumblr", "Grow on Tumblr"],
    tumblrAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/tumblr",
        },
    },

    og: {
        title: "Schedule Posts on Tumblr",
        description: "Auto-post text, images, and links to Tumblr.",
        url: "https://postly.ai/tumblr",
        type: "website",
    },

    tumblrPublishedDate: "2023-12-12T12:00:00Z",
    tumblrModifiedDate: "2023-12-12T14:30:00Z",
    tumblrCategory: "Tumblr publishing",
    tumblrTags: ["schedule posts on tumblr", "tumblr post scheduler", "tumblr post automation", "automated tumblr posts", "tumblr content scheduling", "best times to post on tumblr", "tumblr scheduling tool", "Auto-post to Tumblr", "Grow on Tumblr"],

    // 118 Blog Caption Generator Platform Page
    blogCaptionGeneratorTitle: "Generate Engaging Blog Captions",
    blogCaptionGeneratorDescription: "Create captivating captions for your blog posts with ease.",
    blogCaptionGeneratorCanonicalUrl: "https://postly.ai/blog-caption-generator",
    blogCaptionGeneratorKeywords: ["generate blog captions", "blog caption generator", "engaging blog captions", "blog post captions", "automatic caption generator", "captivating blog captions", "caption ideas for blogs", "blog title generator", "grow your blog"],
    blogCaptionGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/blog-caption-generator",
        },
    },

    og: {
        title: "Generate Engaging Blog Captions",
        description: "Create captivating captions for your blog posts with ease.",
        url: "https://postly.ai/blog-caption-generator",
        type: "website",
    },

    blogCaptionGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    blogCaptionGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    blogCaptionGeneratorCategory: "Blogging Tools",
    blogCaptionGeneratorTags: ["generate blog captions", "blog caption generator", "engaging blog captions", "blog post captions", "automatic caption generator", "captivating blog captions", "caption ideas for blogs", "blog title generator", "grow your blog"],


    // 119 Blog Idea Generator Platform Page
    blogIdeaGeneratorTitle: "Generate Unique Blog Ideas",
    blogIdeaGeneratorDescription: "Discover fresh and engaging blog topics tailored to your audience.",
    blogIdeaGeneratorCanonicalUrl: "https://postly.ai/blog-idea-generator",
    blogIdeaGeneratorKeywords: ["blog idea generator", "generate blog topics", "unique blog ideas", "blog topic generator", "content ideas for blogs", "blog post inspiration", "creative blog ideas", "blogging ideas tool", "grow your blog"],
    blogIdeaGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/blog-idea-generator",
        },
    },

    og: {
        title: "Generate Unique Blog Ideas",
        description: "Discover fresh and engaging blog topics tailored to your audience.",
        url: "https://postly.ai/blog-idea-generator",
        type: "website",
    },

    blogIdeaGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    blogIdeaGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    blogIdeaGeneratorCategory: "Blogging Tools",
    blogIdeaGeneratorTags: ["blog idea generator", "generate blog topics", "unique blog ideas", "blog topic generator", "content ideas for blogs", "blog post inspiration", "creative blog ideas", "blogging ideas tool", "grow your blog"],

    // 120 Blog Title Generator Platform Page
    blogTitleGeneratorTitle: "Generate Catchy Blog Titles",
    blogTitleGeneratorDescription: "Create compelling blog titles that attract readers and improve engagement.",
    blogTitleGeneratorCanonicalUrl: "https://postly.ai/blog-title-generator",
    blogTitleGeneratorKeywords: ["blog title generator", "generate blog titles", "catchy blog titles", "blog headline generator", "creative blog titles", "blog title ideas", "engaging blog titles", "title generator for blogs", "improve blog engagement"],
    blogTitleGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/blog-title-generator",
        },
    },

    og: {
        title: "Generate Catchy Blog Titles",
        description: "Create compelling blog titles that attract readers and improve engagement.",
        url: "https://postly.ai/blog-title-generator",
        type: "website",
    },

    blogTitleGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    blogTitleGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    blogTitleGeneratorCategory: "Blogging Tools",
    blogTitleGeneratorTags: ["blog title generator", "generate blog titles", "catchy blog titles", "blog headline generator", "creative blog titles", "blog title ideas", "engaging blog titles", "title generator for blogs", "improve blog engagement"],


    // 121 Instagram Bio Generator Platform Page
    instagramBioGeneratorTitle: "Generate Perfect Instagram Bios",
    instagramBioGeneratorDescription: "Create eye-catching Instagram bios to attract followers and showcase your personality.",
    instagramBioGeneratorCanonicalUrl: "https://postly.ai/instagram-bio-generator",
    instagramBioGeneratorKeywords: ["instagram bio generator", "generate instagram bios", "catchy instagram bios", "bio ideas for instagram", "creative instagram bios", "instagram bio inspiration", "unique instagram bios", "engaging instagram bios", "grow on instagram"],
    instagramBioGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/instagram-bio-generator",
        },
    },

    og: {
        title: "Generate Perfect Instagram Bios",
        description: "Create eye-catching Instagram bios to attract followers and showcase your personality.",
        url: "https://postly.ai/instagram-bio-generator",
        type: "website",
    },

    instagramBioGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    instagramBioGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    instagramBioGeneratorCategory: "Social Media Tools",
    instagramBioGeneratorTags: ["instagram bio generator", "generate instagram bios", "catchy instagram bios", "bio ideas for instagram", "creative instagram bios", "instagram bio inspiration", "unique instagram bios", "engaging instagram bios", "grow on instagram"],


    // 122 Instagram Username Generator Platform Page
    instagramUsernameGeneratorTitle: "Generate Unique Instagram Usernames",
    instagramUsernameGeneratorDescription: "Find creative and memorable Instagram usernames to stand out and attract followers.",
    instagramUsernameGeneratorCanonicalUrl: "https://postly.ai/free-tools/instagram-username-generator",
    instagramUsernameGeneratorKeywords: ["instagram username generator", "generate instagram usernames", "unique instagram usernames", "username ideas for instagram", "creative instagram usernames", "cool instagram usernames", "catchy instagram usernames", "instagram handle generator", "grow on instagram"],
    instagramUsernameGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/instagram-username-generator",
        },
    },

    og: {
        title: "Generate Unique Instagram Usernames",
        description: "Find creative and memorable Instagram usernames to stand out and attract followers.",
        url: "https://postly.ai/free-tools/instagram-username-generator",
        type: "website",
    },

    instagramUsernameGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    instagramUsernameGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    instagramUsernameGeneratorCategory: "Social Media Tools",
    instagramUsernameGeneratorTags: ["instagram username generator", "generate instagram usernames", "unique instagram usernames", "username ideas for instagram", "creative instagram usernames", "cool instagram usernames", "catchy instagram usernames", "instagram handle generator", "grow on instagram"],


    // 123 Post Call to Action Generator Platform Page
    postCallToActionGeneratorTitle: "Generate Engaging Post Call-to-Actions",
    postCallToActionGeneratorDescription: "Create compelling call-to-action phrases for your posts to increase engagement and conversions.",
    postCallToActionGeneratorCanonicalUrl: "https://postly.ai/free-tools/post-call-to-action-generator",
    postCallToActionGeneratorKeywords: ["call to action generator", "post call to action", "generate call to action", "CTA generator", "call to action ideas", "engaging CTAs", "effective call to action", "social media CTAs", "increase engagement"],
    postCallToActionGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/post-call-to-action-generator",
        },
    },

    og: {
        title: "Generate Engaging Post Call-to-Actions",
        description: "Create compelling call-to-action phrases for your posts to increase engagement and conversions.",
        url: "https://postly.ai/free-tools/post-call-to-action-generator",
        type: "website",
    },

    postCallToActionGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    postCallToActionGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    postCallToActionGeneratorCategory: "Social Media Tools",
    postCallToActionGeneratorTags: ["call to action generator", "post call to action", "generate call to action", "CTA generator", "call to action ideas", "engaging CTAs", "effective call to action", "social media CTAs", "increase engagement"],


    // 124 Post Caption Generator Platform Page
    postCaptionGeneratorTitle: "Generate Creative Post Captions",
    postCaptionGeneratorDescription: "Craft engaging captions for your posts to boost visibility and connect with your audience.",
    postCaptionGeneratorCanonicalUrl: "https://postly.ai/free-tools/post-caption-generator",
    postCaptionGeneratorKeywords: ["post caption generator", "generate captions for posts", "creative post captions", "caption ideas", "social media caption generator", "engaging captions", "catchy captions for posts", "boost post engagement", "caption tool"],
    postCaptionGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/post-caption-generator",
        },
    },

    og: {
        title: "Generate Creative Post Captions",
        description: "Craft engaging captions for your posts to boost visibility and connect with your audience.",
        url: "https://postly.ai/free-tools/post-caption-generator",
        type: "website",
    },

    postCaptionGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    postCaptionGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    postCaptionGeneratorCategory: "Social Media Tools",
    postCaptionGeneratorTags: ["post caption generator", "generate captions for posts", "creative post captions", "caption ideas", "social media caption generator", "engaging captions", "catchy captions for posts", "boost post engagement", "caption tool"],


    // 125 Post Idea Generator Platform Page
    postIdeaGeneratorTitle: "Generate Unique Post Ideas",
    postIdeaGeneratorDescription: "Discover creative ideas for your posts to keep your audience engaged and grow your online presence.",
    postIdeaGeneratorCanonicalUrl: "https://postly.ai/free-tools/post-idea-generator",
    postIdeaGeneratorKeywords: ["post idea generator", "generate post ideas", "social media post ideas", "creative post ideas", "content ideas for social media", "engaging post ideas", "unique post ideas", "boost online presence", "social media content ideas"],
    postIdeaGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/post-idea-generator",
        },
    },

    og: {
        title: "Generate Unique Post Ideas",
        description: "Discover creative ideas for your posts to keep your audience engaged and grow your online presence.",
        url: "https://postly.ai/free-tools/post-idea-generator",
        type: "website",
    },

    postIdeaGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    postIdeaGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    postIdeaGeneratorCategory: "Social Media Tools",
    postIdeaGeneratorTags: ["post idea generator", "generate post ideas", "social media post ideas", "creative post ideas", "content ideas for social media", "engaging post ideas", "unique post ideas", "boost online presence", "social media content ideas"],


    // 126 Post Description Generator Platform Page
    postDescriptionGeneratorTitle: "Generate Compelling Post Descriptions",
    postDescriptionGeneratorDescription: "Create detailed and engaging descriptions for your posts to captivate your audience and drive engagement.",
    postDescriptionGeneratorCanonicalUrl: "https://postly.ai/free-tools/post-description-generator",
    postDescriptionGeneratorKeywords: ["post description generator", "generate post descriptions", "social media post descriptions", "engaging post descriptions", "creative descriptions for posts", "detailed post descriptions", "post description ideas", "boost engagement", "social media content"],
    postDescriptionGeneratorAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools/post-description-generator",
        },
    },

    og: {
        title: "Generate Compelling Post Descriptions",
        description: "Create detailed and engaging descriptions for your posts to captivate your audience and drive engagement.",
        url: "https://postly.ai/free-tools/post-description-generator",
        type: "website",
    },

    postDescriptionGeneratorPublishedDate: "2023-12-12T12:00:00Z",
    postDescriptionGeneratorModifiedDate: "2023-12-12T14:30:00Z",
    postDescriptionGeneratorCategory: "Social Media Tools",
    postDescriptionGeneratorTags: ["post description generator", "generate post descriptions", "social media post descriptions", "engaging post descriptions", "creative descriptions for posts", "detailed post descriptions", "post description ideas", "boost engagement", "social media content"],

    // 126 Free tools
    freeToolsTitle: "Explore Powerful Free Tools for Social Media Success",
    freeToolsDescription: "Access a suite of free tools designed to enhance your social media strategy. Generate creative post ideas, optimize your content, and grow your audience effortlessly.",
    freeToolsCanonicalUrl: "https://postly.ai/free-tools",
    freeToolsKeywords: [
        "free social media tools",
        "post idea generator",
        "free content creation tools",
        "social media optimization tools",
        "generate post ideas",
        "creative post ideas",
        "boost online presence",
        "engage your audience",
        "social media growth tools"
    ],
    freeToolsAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/free-tools",
        },
    },

    og: {
        title: "Explore Powerful Free Tools for Social Media Success",
        description: "Access a suite of free tools designed to enhance your social media strategy. Generate creative post ideas, optimize your content, and grow your audience effortlessly.",
        url: "https://postly.ai/free-tools",
        type: "website",
    },

    freeToolsPublishedDate: "2023-12-12T12:00:00Z",
    freeToolsModifiedDate: "2023-12-12T14:30:00Z",
    freeToolsCategory: "Social Media Tools",
    freeToolsTags: [
        "free social media tools",
        "post idea generator",
        "content creation tools",
        "social media post ideas",
        "creative post ideas",
        "content optimization tools",
        "engaging post ideas",
        "unique post ideas",
        "grow your audience"
    ],
    // 127 X to Bluesky Feature
    xToBlueskyTitle: "Seamlessly Crosspost from X to Bluesky",
    xToBlueskyDescription: "Effortlessly publish your X (formerly Twitter) content to Bluesky. Redistribute old posts, schedule updates, and sync new content in real-time for consistent engagement across platforms.",
    xToBlueskyCanonicalUrl: "https://postly.ai/x-to-bluesky",
    xToBlueskyKeywords: [
        "X to Bluesky crossposting",
        "publish X posts to Bluesky",
        "redistribute Twitter content",
        "schedule social media posts",
        "X to Bluesky integration",
        "cross-platform social media posting",
        "sync X and Bluesky",
        "automated post scheduling",
        "real-time crossposting"
    ],
    xToBlueskyAuthor: "Paul Onu",

    canonical: {
        link: {
            rel: "canonical",
            href: "https://postly.ai/x-to-bluesky",
        },
    },

    og: {
        title: "Seamlessly Crosspost from X to Bluesky",
        description: "Effortlessly publish your X (formerly Twitter) content to Bluesky. Redistribute old posts, schedule updates, and sync new content in real-time for consistent engagement across platforms.",
        url: "https://postly.ai/x-to-bluesky",
        type: "website",
    },

    xToBlueskyPublishedDate: "2023-12-12T12:00:00Z",
    xToBlueskyModifiedDate: "2023-12-12T14:30:00Z",
    xToBlueskyCategory: "Social Media Tools",
    xToBlueskyTags: [
        "X to Bluesky crossposting",
        "Twitter to Bluesky posts",
        "content redistribution",
        "social media post scheduler",
        "cross-platform integration",
        "real-time post syncing",
        "engagement tools for Bluesky",
        "grow audience on Bluesky",
        "social media automation"
    ],

};


module.exports = SiteMetaData